<script>
import BaseButton from '@/shared/components/BaseButton.vue';
import AddMentorsModel from '@/modules/mentors/add-mentors-model';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';

const { fields } = AddMentorsModel;

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      fields,
      files: [],
      model: {},
      error: '',
      dropped: 0,
      Imgs: [],
      update: 0,
    };
  },
  methods: {
    getUser(idUser) {
      const getUserPath = '/WebGetUser.php';

      const data = new FormData();
      data.append('id', idUser);
      fetch(server + getUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            [this.model.first_name, this.model.last_name] = json.name.split(' ');
            this.model.email = json.username;
            this.model.type = json.type;
            this.update += 1;
          }
        });
    },
    addStudent() {
      const data = new FormData();
      let addStudentPath = '';
      if (parseInt(this.$route.params.id, 10) !== 0) {
        addStudentPath = '/WebEditUser.php';
        data.append('id', this.$route.params.id);
      } else {
        addStudentPath = '/WebAddUser.php';
      }

      data.append('name', `${this.model.first_name} ${this.model.last_name}`);
      data.append('username', this.model.email);
      data.append('role', 'student');
      data.append('active', true);
      data.append('group_ids', parseInt(this.$route.params.parent_id, 10));
      fetch(server + addStudentPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === 'error') {
            if (json.error.includes('Wrong token')) {
              this.$router.push({ name: 'login' });
              destroyToken();
            } else {
              Notify('warning', json.error);
            }
          } else if (json.status === 'ok') {
            this.$router.push(`/management/student/students-results/${this.$route.params.parent_id}`);
          }
        });
    },
  },
  beforeMount() {
    if (parseInt(this.$route.params.id, 10) !== 0) {
      this.getUser(this.$route.params.id);
    }
  },
};
</script>
<template lang="html">
  <div :key="update">
    <b-row>
      <b-col class="add-news-title" v-if="parseInt(this.$route.params.id, 10) === 0">
        {{ $t("DEPARTAMENTS.ADD_STUDENT") }}
      </b-col>
      <b-col class="add-news-title" v-if="parseInt(this.$route.params.id, 10) !== 0">
        {{ $t("DEPARTAMENTS.EDIT_STUDENT") }}
      </b-col>
    </b-row>
    <div class="separator">

    </div>
    <div class="add-news-subtitle">
      {{ $t("DEPARTAMENTS.COMPLETE_AND_SAVE") }}
    </div>
    <b-row class="mb-4">
      <b-col lg="3" class="ps-0">
        <div class="form-floating">
          <input type="text"
                 class="form-control floatingInput"
                 :id="fields.first_name.name"
                 v-model="model[fields.first_name.name]"
                 :placeholder="fields.first_name.label"
          >
          <label for="floatingInput">
            {{ fields.first_name.label }}</label>
        </div>
      </b-col>
      <b-col lg="3" class="pe-0">
        <div class="form-floating">
          <input type="text"
                 class="form-control floatingInput"
                 :id="fields.last_name.name"
                 v-model="model[fields.last_name.name]"
                 :placeholder="fields.last_name.label"
          >
          <label for="floatingInput">
            {{ fields.last_name.label }}</label>
        </div>
      </b-col>
      <b-col lg="3" class="pe-0">
        <div class="form-floating">
          <input type="text"
                 class="form-control floatingInput"
                 :id="fields.email.name"
                 v-model="model[fields.email.name]"
                 :placeholder="fields.email.label"
          >
          <label for="floatingInput">
            {{ fields.email.label }}</label>
        </div>
      </b-col>
      <b-col style="display: flex; vertical-align: center; align-items: center">
        <BaseButton
          :callback="addStudent"
          :callback-params="1"
          :variant="'primary'"
        >
          {{ $t('GENERAL.SAVE') }}
        </BaseButton>
      </b-col>
    </b-row>
    <div class="separator">
    </div>
  </div>
</template>

<style lang="scss">
.add-news-title {
  font-family: Nunito, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #22232F;
  margin-bottom: 30px;
}

.select-admin {
  margin-bottom: 16px;
}
.separator {
  height: 2px;
  border: 1px solid #D9DBE3;
  margin-bottom: 20px;
}

.add-news-subtitle {
  font-family: Nunito, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #22232F;
  margin-bottom: 16px;
}

.add-news-description {
  background: #FDFDFD;
  border: 1px solid #D9DBE3;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
  border-radius: 8px;
  margin-bottom: 36px;
  width: 100%;
}

.container-button {
  position: relative;
}

.container-button button {
  position: absolute;
  right: 0;
}

.container {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0.5px solid #a3a8b1;
  padding: 12px;
  position: relative;
  border: 0.5px dashed #9A9FA7;
  box-sizing: border-box;
  filter: drop-shadow(2px 2px 4px rgba(166, 171, 189, 0.25));
  border-radius: 8px;
  margin-bottom: 30px;
}
.drop {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 10px;
  position: absolute;
  background-color: #f4f6ff;
  left: 0;
  border: 3px dashed #a3a8b1;
}
.error {
  text-align: center;
  color: red;
  font-size: 15px;
}
.beforeUpload {
  position: relative;
  text-align: center;
}

.beforeUpload .plus-image{
  height: 24px;
  width: 24px;
  display: inline-flex;
  margin-right: 8px;
}
.beforeUpload input {
  width: 100%;
  margin: auto;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: red;
  display: block;
}
.beforeUpload input:hover {
  cursor: pointer;
}
.beforeUpload .icon {
  width: 150px;
  margin: auto;
  display: block;
}
.imgsPreview .imageHolder {
  width: 150px;
  height: 150px;
  background: #fff;
  position: relative;
  margin: 5px 5px;
  display: inline-block;
}
.imgsPreview .imageHolder img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.imgsPreview .imageHolder .delete {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.imgsPreview .imageHolder .delete:hover {
  cursor: pointer;
}
.imgsPreview .imageHolder .delete .icon {
  width: 66%;
  height: 66%;
  display: block;
  margin: 4px auto;
}
.imgsPreview .imageHolder .plus {
  color: #2d3748;
  background: #f7fafc;
  border-radius: 50%;
  font-size: 21pt;
  height: 30px;
  width: 30px;
  text-align: center;
  border: 1px dashed;
  line-height: 23px;
  position: absolute;
  right: -42px;
  bottom: 43%;
}
.plus:hover {
  cursor: pointer;
}
.clearButton {
  color: #2d3748;
  position: absolute;
  top: 7px;
  right: 7px;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
